@import 'bootstrap';
@import 'variables';
@import 'colours';
@import 'typography';
@import 'mixins';
@import 'functions';

/* stylelint-disable */
body {
    overflow-y: scroll;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

html.font-size-1 {
    font-size: multiply-font-size(1);
}

html.font-size-2 {
    font-size: multiply-font-size(1.2);
}

html.font-size-3 {
    font-size: multiply-font-size(1.4);
}

svg {
    & .icon-contour-fill {
        fill: $primary;
    }

    & .icon-contour-stroke {
        stroke: $primary;
    }
}

/* stylelint-enable */

.content {
    flex: 1;
    overflow-y: visible;
}

.container {
    @include media-breakpoint-down(md) {
        max-width: 100%;
    }
}

@media print {
    .no-print {
        display: none;
    }
}
