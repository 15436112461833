:root {
    --white-color: #ffffff;
    --black-color: #0a1423;
    --primary-color: #a54027;
    --primary-color-lighten-5: #a54027;
    --primary-color-lighten-10: #a54027;
    --primary-color-darken-10: #a54027;
    --primary-color-darken-20: #a54027;
    --secondary-color: #f9eeea;
}

$primary: var(--primary-color);
$primary-lighten-5: var(--primary-color-lighten-5);
$primary-lighten-10: var(--primary-color-lighten-10);
$primary-darken-10: var(--primary-color-darken-10);
$primary-darken-20: var(--primary-color-darken-20);
$secondary: var(--secondary-color);
$secondary-lighten-10: var(--secondary-color-lighten-10);
$color--placeholder: #767676;

$black: var(--black-color);
$black--h1: #212e4a;
$white: var(--white-color);
$flash-white: #f1f3f5;
$lilac-white: #e8e8e8;
$dark-white: #f4f4f4;
$green: var(--green-color);
$green-darken-10: var(--green-color-darken-10);
$green-darken-20: var(--green-color-darken-20);
$blue: var(--blue-color);
$blue-darken-10: var(--blue-color-darken-10);
$gray: var(--gray-color);
$light-gray: #d7dee5;
$hover-gray: #f4f4f4;
$light-black: #212e4a;
$graphite: #5b626c;
$font-color: #363636;
$search-button-color: var(--search-button-color);
$search-button-icon-color: var(--search-button-icon-color);
$sidemenu-text-color-on-primary: var(--sidemenu-text-color-on-primary);
$sidemenu-text-color-on-secondary: var(--sidemenu-text-color-on-secondary);
