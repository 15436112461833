@import 'variables';

@mixin hide-text() {
    overflow: hidden;
    text-indent: -100vw;
    display: block;
}

@mixin line-100 {
    content: '';
    width: 100vw;
    bottom: 0;
    position: absolute;
    display: block;
    left: 50%;
    transform: translate(-50%);
    background-color: $lilac-white;
    height: 1px;
}

@mixin spin-around($duration, $from, $to) {
    animation: spin-around $duration infinite linear;

    @keyframes spin-around {
        0% {
            transform: rotate($from);
        }

        100% {
            transform: rotate($to);
        }
    }
}

@mixin conditional-border-radius($value) {
    border-radius: calc(#{$value} * #{$border-radius-enabled});
}
