@import '../../styles';

.page404__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px 15px;

    .icon-contour-fill {
        fill: $primary;
    }
}

.page404__title {
    margin: 60px 0 20px;
    color: $black--h1;
    font-size: px-to-rem(32px);
    line-height: 1.2;
}

.page404__desc {
    color: $black;
    line-height: 1.56;
    text-align: center;
}

.page404__btns-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    @include media-breakpoint-down(md) {
        flex-direction: column;

        .page404__btn-main-page {
            margin-top: 25px;
            margin-left: 0;
        }
    }
}

.page404__btn-back {
    min-width: 360px;
}

.page404__btn-main-page {
    margin-left: 40px;
    flex-shrink: 0;
    text-transform: uppercase;
    color: $primary;
    font-weight: 600;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $primary-darken-10;
    }
}
