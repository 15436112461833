@import 'functions';

body {
    font-family: $text-font;
    font-weight: 400;
    font-size: px-to-rem(18px);
}

h1,
h2,
h3,
h4 {
    font-family: $title-font;
    font-weight: 700;
    color: $black;
}

h1 {
    font-size: px-to-rem(20px);
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
        font-size: px-to-rem(32px);
    }
}

h2 {
    font-size: px-to-rem(18px);
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
        font-size: px-to-rem(24px);
    }
}

h3 {
    font-size: px-to-rem(18px);
    line-height: 1.5;

    @include media-breakpoint-up(lg) {
        font-size: px-to-rem(20px);
    }
}

h4 {
    font-size: px-to-rem(18px);
    line-height: 1.5;
}

h6 {
    font-family: $title-font;
    font-weight: 700;
    color: $black;
    font-size: px-to-rem(12px);
    line-height: 1.5;
    text-transform: uppercase;
}
