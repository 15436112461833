html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

@import 'bootstrap/functions';
@import 'bootstrap/variables';

@import 'bootstrap/mixins/breakpoints';
@import 'bootstrap/mixins/grid-framework';
@import 'bootstrap/mixins/grid';

@import 'bootstrap/grid';
@import 'bootstrap/utilities/display';
@import 'bootstrap/utilities/flex';
@import 'bootstrap/utilities/spacing';
