@import 'variables';

$default-browser-font-size: 16px;
$base-font-size: $default-browser-font-size;

@function multiply-font-size($multiplier) {
    @return percentage(
        $base-font-size / $default-browser-font-size * $multiplier
    );
}

@function px-to-rem($px) {
    @return $px / $base-font-size * 1rem;
}
