:root {
    --dashboard-bg: #ffffff;
    --title-font: 'Poppins', sans-serif;
    --text-font: 'Open Sans', Helvetica, Arial, sans-serif;
}

$dashboard-bg: var(--dashboard-bg);
$title-font: var(--title-font);
$text-font: var(--text-font);
$border-radius-enabled: var(--border-radius-enabled);
